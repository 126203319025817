<template>
  <div>
    <div
      role="button"
      class="d-flex button-title"
      @click="$router.push({ name: 'passport' })"
    >
      <!--      todo: изменить кнопку назад, после таски back_route -->
      <div>
        <v-icon size="40">mdi-chevron-left</v-icon>
      </div>
      <div class="pl-2">
        <v-card-title class="pl-0 text-subtitle-1 white--text pt-0 pb-3">{{
            value.properties.object_name
          }}
        </v-card-title>
        <v-card-subtitle class="pl-0">{{ currentTypePassport }}</v-card-subtitle>
      </div>
    </div>
    <v-card-subtitle class="pb-1 pl-8 pt-0">Контроль работы подрядчика</v-card-subtitle>
    <v-select
      :items="itemsScenario"
      item-text="title"
      item-value="id"
      class="pl-8 pr-8"
      outlined
      dense
      v-model="currentScenario"
    ></v-select>
    <v-divider></v-divider>
    <v-list
      dense
      dark
    >
      <v-list-item-group
        mandatory
        v-model="currentActiveItem"
      >
        <v-divider></v-divider>
        <BaseListItem
          v-for="(item,i) in sideBarItems"
          :key="i+ '-'"
          :item="item"
        />
        <v-divider></v-divider>
        <v-subheader>Объекты</v-subheader>
        <ListItemObject
          v-for="(item, i) in value.properties.passportEntities"
          :key="i"
          :item="item"
        />
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { collectMenuItems, currentActiveWithOffset } from '@/components/utils/common'
import BaseListItem from '@/components/base/BaseNav/BaseListItem'
import menuItems from '@/config/menuConfigs/passportMenuConfig'
import ListItemObject from '@/components/views/account/passport/detail/sideBar/ListItemObject'

export default {
  name: 'SideBar',
  components: { ListItemObject, BaseListItem },
  props: {
    scenario: String,
    id: Number,
    value: Object
  },
  data () {
    return {
      currentActiveItem: null,
      itemsScenario: [
        { title: 'Верифицированные', id: 'origin' },
        { title: 'Актуальные', id: 'commit' },
        { title: 'Измененные', id: 'diff' }
      ],
      sideBarItems: []
    }
  },
  watch: {
    id: {//если id меняется значит паспорт создан
      handler () {
        this.sideBarItems = collectMenuItems(menuItems)
      },
      immediate: true
    },
    '$route.name': {
      handler () {
        this.createCurrentActiveItem()
      }
    }
  },
  computed: {
    currentScenario: {
      get () {
        return this.scenario
      },
      set (val) {
        this.$emit('changeScenario', val)
      }
    },
    currentTypePassport () {
      const types = this.$store.getters['serviceData/get']('passport.type') || []
      return types.find((item) => item.value === this.value.properties.type)?.label || ''
    }
  },
  methods: {
    createCurrentActiveItem () {
      //текущий элемент это номер(индекс). Определяется в "общем" массиве (passportEntities + sideBarItems), но при mounted этот элемент сам не определяется,
      // поэтому используется createCurrentActiveItem. Верхние элементы это sideBarItems, нижние(объекты) - passportEntities. Индексы нижних смещены на offsetElements
      const offsetElements = this.sideBarItems.length
      const currentId = Number(this.$route.params.entityId)
      const findCurrentActiveElement = this.value.properties.passportEntities.findIndex((item) => item.eavEntity.id === currentId)
      const findCurrentActiveElement2 = this.sideBarItems.findIndex((item) => item.link === this.$route.path)
      this.currentActiveItem = currentActiveWithOffset(findCurrentActiveElement2, findCurrentActiveElement, offsetElements)
    }
  },
  mounted () {
    if (this.value.properties.passportEntities && this.sideBarItems) {
      this.createCurrentActiveItem()
    }
  }
}
</script>

<style scoped>
  .button-title:hover {
    background-color: rgba(178, 178, 178, 0.26)
  }
</style>
