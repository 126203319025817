<template>
  <BaseDetail
    :is-loading="loading"
    v-if="detail"
  >
    <template #sidebar>
      <BaseSideBar
        dark
        :class="classByPassportType"
      >
        <v-card
          flat
          :class="classByPassportType"
        >
          <v-card-text>
            <SideBar
              :id="detail.properties.id"
              @changeScenario="changeScenario"
              :scenario="currentScenario"
              :value="detail"
            />
          </v-card-text>
        </v-card>
      </BaseSideBar>
    </template>
    <template
      #header
      v-model="detail.properties.passportEntities"
    >
      <nobr></nobr>
    </template>
    <template #content>
      <div>
        <router-view
          :key="$route.path"
          v-if="detail"
          v-model="detail"
          :scenario="currentScenario"
          ref="content"
          @save="handleSave"
          @delete="handleDelete"
          @close="handleClose"
        ></router-view>
      </div>
    </template>
  </BaseDetail>
</template>

<script>
import SideBar from '@/components/views/account/passport/detail/SideBar'
import BaseDetail from '@/components/base/BaseDetail'
import detail from '@/components/mixins/detail'
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import validationRulesPassport from '@/components/mixins/validation/passport'
import BaseSideBar from '@/components/base/BaseSideBar'
import BaseTree from '@/components/base/BaseTree'
import { verificationScenarioByRole } from '@/components/utils/common'
import '@/css/passportTypes.css'
import backToTables from '@/components/mixins/backToTables'
import classByPassportType from '@/components/views/account/map/classByPassportType'

export default {
  mixins: [detail, backToTables, validationRulesPassport, classByPassportType],
  name: 'Detail',
  components: { BaseTree, BaseSideBar, BaseDetail, SideBar, BaseHeader },
  data() {
    return {
      currentScenario: '',
      params: { additionalParams: { organization_id: this.$store.getters['auth/getProfile'].organization.id } }
    }
  },
  computed: {
    localDetail () {
      return this.detail.properties
    },
    controllerName () {
      return 'passport'
    }
  },
  methods: {
    changeScenario (e) {
      this.$set(this, 'currentScenario', e)
    },
    setVerificationScenario () {
      if (this.$route.query?.filter?.verificationScenario) {
        this.$set(this, 'currentScenario', this.$route.query.filter.verificationScenario)
      } else if(!this.currentScenario){
        this.$set(this, 'currentScenario', verificationScenarioByRole())
      }
    }
  },
  mounted () {
    this.setVerificationScenario()
  }
}
</script>

<style scoped>
  .nav {
    background: cadetblue;
  }
/*  .content {
    background: #EBF3FA;
  }*/
  .default {
    background: rgb(108,117,122) !important;
    background: linear-gradient(90deg, rgba(108,117,122,1) 0%, rgba(43,64,110,1) 100%) !important;
    color: white !important
  }
</style>
